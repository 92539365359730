import classNames from "classnames";

import style from "./style.module.css";

interface Props {
  children: React.ReactNode;
  type?: "submit" | "button";
  color?: "default" | "red";
  disabled?: boolean;
  loading?: boolean;
  loadingLabel?: string;
  onClick?: () => void;
}

export function Button({ children, type = "button", color = "default", disabled = false, loading = false, loadingLabel = "Saving...", onClick }: Props) {
  
  return (
    <button
      type={type}
      disabled={disabled || loading}
      className={classNames("button", style.container, style[color])}
      onClick={onClick}
    >
      {loading ? loadingLabel : children}
    </button>
  );
 
}
