import classNames from "classnames";

import style from "./style.module.css";

interface Props {
  children: React.ReactNode;
  className?: string;
}

export function Section({ children, className }: Props) {
  
  return (
    <section className={classNames(style.container, className)}>
      {children}
    </section>
  );
 
}
