import classNames from "classnames";
import { Column } from "components/Listing/Column";

import style from "./style.module.css";

interface Props {
  children: React.ReactNode;
  width: string;
  center?: boolean;
  className?: string;
}

export function Head({ children, width, center = false, className }: Props) {
  
  return (
    <Column center={center} width={width} className={classNames(style.container, className)}>
      {children}
    </Column>
  );
 
}
