import classNames from "classnames";

import style from "./style.module.css";

interface Props {
  icon: string;
  size?: "default" | "small";
}

export function Icon({ icon, size = "default" }: Props) {
  return <i className={classNames("icon", style.container, "material-icons", style[size])}>{icon}</i>;
}
