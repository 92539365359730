import classNames from "classnames";

import style from "./style.module.css";

interface Props {
  children: React.ReactNode;
  color?: "blue" | "green" | "yellow" | "red" | "orange" | "purple" | "white";
  slim?: boolean;
}

export function Box({ children, color = "blue", slim = false }: Props) {
  
  return (
    <div className={classNames(style.container, style[color], slim && style.slim)}>
      {children}
    </div>
  );
 
}
