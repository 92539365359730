import classNames from "classnames";

import style from "./style.module.css";

interface Props {
  children: React.ReactNode;
  tip?: string;
}

export function Label({ children, tip }: Props) {
  
  return (
    <div className={classNames(style.container, tip && style.tipped)}>
      <p className={style.label}>{children}</p>
      {tip && <small className={style.tip}>{tip}</small>}
    </div>
  );
 
}
