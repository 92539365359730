import style from "./style.module.css";

interface Props {
  children: React.ReactNode;
}

export function Overlay({ children }: Props) {
  
  return (
    <div className={style.container}>
      <div className={style.inner}>
        {children}
      </div>
    </div>
  );
 
}
