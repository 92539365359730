import { useAlert } from "components/Alert/useAlert";
import { Box } from "components/Box";
import { Input } from "components/Form/Input";
import { Section } from "components/Layout/Section";
import { Listing } from "components/Listing";
import { Column } from "components/Listing/Column";
import { Head } from "components/Listing/Head";
import { Row } from "components/Listing/Row";
import { useLoader } from "components/Loader/useLoader";
import { Pagination } from "components/Pagination";
import dateFormat from "dateformat";
import { useState } from "react";
import { Link } from "react-router-dom";
import { studio } from "services";
import * as Types from "types";

import style from "./style.module.css";

export function Users() {
  const loader = useLoader();
  const alert = useAlert();
  const [ search, setSearch ] = useState("");
  const [ results, setResults ] = useState<Types.UserSearch.Response | undefined>(undefined);

  const onKeyDown = async (key: string) => {
    if (search && key === "Enter") {
      await onSearch();
    }
  };

  const onSearch = async (offset = 0) => {
    loader.start();
    alert.clear();
    try {
      const request = await studio.management.searchUsers({
        filter: search,
        offset,
      });
      setResults(request);
      loader.stop();
    } catch (err: any) {
      loader.stop();
      alert.setErrorMessage(err.message);
    }
  };

  const onPrevClick = async (offset: number) => {
    await onSearch(offset);
  };

  const onNextClick = async (offset: number) => {
    await onSearch(offset);
  };

  const renderLanding = () => {
    return (
      <div className={style.notice}>
        Start by performing a search above
      </div>
    );
  };

  const renderResults = () => {
    if (!results || !results.pagination.total) {
      return (
        <div className={style.notice}>
          Your search returned no results
        </div>
      );
    }
    return (
      <>
        {/* Listing */}
        <Section>
          <Listing>
            <Row className={style.row}>
              <Head width="50%">Email</Head>
              <Head center width="10%">Logins</Head>
              <Head width="20%">Last Logged In</Head>
              <Head width="20%">Created On</Head>
            </Row>
            {results.results.map(result => (
              <Row className={style.row} key={result.id}>
                <Column width="50%">
                  <Link to={`/users/${result.id}`}>{result.email}</Link>
                </Column>
                <Column center width="10%">{result.login_count}</Column>
                <Column width="20%">{result.last_login_at ? dateFormat(result.last_login_at, "dd/mm/yyyy") : "Never"}</Column>
                <Column width="20%">{dateFormat(result.created_at, "dd/mm/yyyy")}</Column>
              </Row>
            ))}
          </Listing>
        </Section>

        {/* Pagination */}
        <Section>
          <Pagination
            first={results.pagination.first}
            last={results.pagination.last}
            total={results.pagination.total}
            limit={results.pagination.limit}
            onPrev={onPrevClick}
            onNext={onNextClick}
          />
        </Section>
      </>
    );
  };

  return (
    <>
      {loader.render()}
      {alert.render()}
      {/* Search */}
      <Section>
        <Box color="blue">
          <Input
            value={search}
            onChange={setSearch}
            placeholder="Search users..."
            onKeyDown={onKeyDown}
            className="bgWhite"
          />
        </Box>
      </Section>

      {results ? renderResults() : renderLanding()}

    </>
  );
 
}