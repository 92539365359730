import style from "./style.module.css";

interface Props {
  children: React.ReactNode;
}

export function Main({ children }: Props) {
  
  return (
    <main className={style.container}>
      {children}
    </main>
  );
 
}
