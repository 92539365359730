import { IAuthClaims, IAuthSession } from "@screencloud/auth-sdk";
import { AppStatus } from "components/AppStatus";
import { useLoader } from "components/Loader/useLoader";
import { AppContext, Context } from "context";
import { Layout } from "layouts";
import { Dashboard } from "pages/Dashboard";
import { Users } from "pages/Users";
import { Edit } from "pages/Users/Edit";
import { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { studio } from "services";

export function App() {
  const loader = useLoader(true);
  const [ status, setStatus ] = useState("Verifying your credentials");
  const [ claims, setClaims ] = useState<IAuthClaims | null | undefined>(undefined);
  const [ token, setToken ] = useState<string | null | undefined>(undefined);
  const context: Context = {
    claims,
    setClaims,
    token,
    setToken,
  };

  useEffect(() => {
    void studio.id.refresh().then(session => {
      sessionValidate(session);
      sessionStore(session);
      sessionListen();
      loader.stop();
    });
  }, []);

  /**
   * Basic validation on the session
   * 
   * @see - `studio-auth-service` will validate properly, this does not constitute success
   */
  const sessionValidate = (session: IAuthSession | null) => {
    if (!session || !session.token || !session.claims) {
      return setStatus("You are not logged in.");
    }
    if (session.claims.provider !== "google-oauth2" || session.claims.connection !== "google-oauth2") {
      return setStatus("You are not logged in with the correct provider.");
    }
    if (!session.claims.email.endsWith("@screencloud.io")) {
      return setStatus("You are not authorized.");
    }
    // All good
    return setStatus("");
  };

  /**
   * Store the session in context
   */
  const sessionStore = (session: IAuthSession | null) => {
    if (session && session.claims && session.token) {
      setClaims(session.claims);
      setToken(session.token);
    } else {
      setClaims(null);
      setToken(null);
    }
  };

  /**
   * Listen for session events
   */
  const sessionListen = () => {
    studio.id.sdk.on("refreshed", ({ claims, token }) => {
      setClaims(claims);
      setToken(token);
      console.log(" %c sdk event %c REFRESHED ", "background: #5787C6; color: #fff", "background: #FC8527; color: #fff; font-weight: bold");
    });
    studio.id.sdk.on("loggedIn", ({ claims, token }) => {
      setClaims(claims);
      setToken(token);
      console.log(" %c sdk event %c LOGGEDIN ", "background: #5787C6; color: #fff", "background: #2FB457; color: #fff; font-weight: bold");
    });
    studio.id.sdk.on("loggedOut", () => {
      setClaims(null);
      setToken(null);
      console.log(" %c sdk event %c LOGGEDOUT ", "background: #5787C6; color: #fff", "background: #D0021B; color: #fff; font-weight: bold");
      // they are logged out now, reload the page to kick them
      window.location.reload();
    });
  };

  const renderStatus = () => {
    return <AppStatus showLogin>{status}</AppStatus>;
  };

  if (loader.isLoading) {
    return (
      <>
        {loader.render()}
        {renderStatus()}
      </>
    );
  }

  if (status) {
    return renderStatus();
  }

  return (
    <AppContext.Provider value={context}>
      <Routes><Route element={<Layout />}>

        <Route path="/" element={<Dashboard />} />
        <Route path="/users" element={<Users />} />
        <Route path="/users/:id" element={<Edit />} />
        
        <Route path="*" element={<div>No such page</div>} />
      </Route></Routes>
    </AppContext.Provider>
  );
  
}
