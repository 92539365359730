/**
 * Validate string as a valid password
 * 
 * @see - studio-auth-service src/libs/utils/isValidPassword()
 */
export function validPassword(str: string): boolean {
  if (typeof str !== "string") {
    return false;
  }
  if (str.length < 8 || str.length > 128) {
    return false;
  }
  return /[a-z]/.test(str) && /[A-Z]/.test(str) && /[0-9]/.test(str);
}

/**
 * Convert a key: value object to a React Select object
 */
export function selectOption(value: string, label: string) {
  return { value, label };
}

/**
 * Convert a key: value object to a React Select array
 */
export function selectOptions(data: Record<string, string>) {
  return Object.entries(data).map(entry => selectOption(entry[0], entry[1]));
}

/**
 * Convert a string to the plural based on the count
 */
export function pluralise(count: number, word: string): string {
  return `${count} ${word}${count === 1 ? "" : "s"}`;
}

/**
 * Capitalize a string
 */
export function capitalize(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}