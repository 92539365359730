import classNames from "classnames";
import md5 from "md5";

import style from "./style.module.css";

interface Props {
  url?: string | null;
  email?: string | null;
  size?: "large" | "small";
  className?: string;
}

export function Avatar({ url, email, size = "large", className }: Props) {
  let uri: string | null = null;
  if (url) {
    uri = url;
  } else if (email) {
    uri = `https://www.gravatar.com/avatar/${md5(email)}?s=120&d=mp`;
  }
  if (uri) {
    return <img src={uri} className={classNames("avatar", style.image, style[size], className)} />;
  }
  return <div className={classNames("avatar", style.missing, style[size], className)}>?</div>;
}


/* export function Avatar({ url, email, size = "large", className }: Props) {
  if (url) {
    return <img src={url} className={classNames("avatar", style.image, style[size], className)} />;
  }
  if (email) {
    const hashed = md5(email);
    return <img src={`https://www.gravatar.com/avatar/${hashed}?s=200&d=mp`} className={classNames("avatar", style.image, style[size], className)} />;
  }
  return <div className={classNames("avatar", style.missing, style[size], className)}>?</div>;
} */