import classNames from "classnames";

import style from "./style.module.css";

interface Props {
  children: React.ReactNode;
  className?: string;
}

export function Listing({ children, className }: Props) {
  
  return (
    <div className={classNames(style.container, className)}>
      {children}
    </div>
  );
 
}
