import classNames from "classnames";
import { Avatar } from "components/Avatar";
import { Icon } from "components/Icon";
import { Logo } from "components/Logo";
import { AppContext } from "context";
import { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { config } from "system/config";

import style from "./style.module.css";

export function Header() {
  const context = useContext(AppContext);
  const location = useLocation();

  const renderLogo = () =>  (
    <div className={style.logo}>
      <Logo />
    </div>
  );

  const renderNav = () => (
    <nav className={style.navigation}>
      <Link to="/" className={classNames(style.nav, location.pathname === "/" && style.active)}>Dashboard</Link>
      <Link to="/users" className={classNames(style.nav, location.pathname.startsWith("/users") && style.active)}>Users</Link>
    </nav>
  );

  const renderUser = () => (
    <div className={style.user}>
      <Avatar url={context.claims?.picture} email={context.claims?.email} size="small" className={style.avatar} />
      <span className={style.name}>Hi, {context.claims?.given_name || "You"}</span>
      <a href={`${config.id.frontend.url}/logout`} className={style.logout} title="Logout"><Icon icon="logout" size="small" /></a>
    </div>
  );

  return (
    <header className={style.container}>
      {renderLogo()}
      {renderNav()}
      {renderUser()}
    </header>
  );
 
}
