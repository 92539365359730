import classNames from "classnames";
import { Provider as IdProvider } from "types";

import style from "./style.module.css";

interface Props {
  provider: IdProvider;
}

export function Provider({ provider }: Props) {
  
  return <i className={classNames(style.container, style[provider])} />;
 
}
