import { useAlert } from "components/Alert/useAlert";
import { Avatar } from "components/Avatar";
import { Box } from "components/Box";
import { Button } from "components/Button";
import { Form } from "components/Form";
import { Buttons } from "components/Form/Buttons";
import { Dropdown } from "components/Form/Dropdown";
import { Field } from "components/Form/Field";
import { GroupTitle } from "components/Form/GroupTitle";
import { Input } from "components/Form/Input";
import { Label } from "components/Form/Label";
import { Readonly } from "components/Form/Readonly";
import { Row } from "components/Form/Row";
import { Textarea } from "components/Form/Textarea";
import { Section } from "components/Layout/Section";
import { useLoader } from "components/Loader/useLoader";
import { Overlay } from "components/Overlay";
import { Provider } from "components/Provider";
import dateFormat from "dateformat";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { studio } from "services";
import { UserStatus } from "system/constants";
import * as Types from "types";
import * as utils from "utils";

export function Edit() {
  const params = useParams<"id">();
  const navigate = useNavigate();
  const loader = useLoader(true);
  const alert = useAlert();
  const id = params.id as string;
  const [user, setUser] = useState<Partial<Types.User>>({});
  const [identities, setIdentities] = useState<Types.UserIdentity[]>();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [email, setEmail] = useState("");
  const [givenName, setGivenName] = useState("");
  const [familyName, setFamilyName] = useState("");
  const [password, setPassword] = useState("");
  const [reason, setReason] = useState("");
  const [statusValue, setStatusValue] = useState("");
  const [statusLabel, setStatusLabel] = useState("");

  useEffect(() => {
    if (!id) {
      return navigate("/?error=NOT_FOUND", { replace: true });
    }
    studio.management.getUser(id).then(data => {
      setUser(data.user);
      setIdentities(data.identities);
      setEmail(data.user.email);
      setGivenName(data.user.given_name || "");
      setFamilyName(data.user.family_name || "");
      setStatusValue(data.user.status);
      setStatusLabel(utils.capitalize(data.user.status));
      loader.stop();
    }).catch(err => {
      return navigate(`/?error=${encodeURIComponent(err.response.data.error.message)}`, { replace: true });
    });
  }, []);

  const onStatusChange = (value: string, label: string) => {
    setStatusValue(value);
    setStatusLabel(label);
  };
  
  const onSubmit = async () => {
    loader.start();
    alert.clear();
    try {
      if (isDisabled()) {
        throw new Error("REQUIRED_FIELDS");
      }
      if (password && !utils.validPassword(password)) {
        throw new Error("INVALID_PASSWORD");
      }
      await studio.management.editUser(id, {
        givenName,
        familyName,
        status: statusValue as Types.UserStatus,
        password,
        reason,
      });
      setPassword("");
      alert.setSuccessMessage("User updated successfully!");
      loader.stop();
    } catch (err: any) {
      loader.stop();
      alert.setErrorMessage(err.message);
    }
  };

  const onDelete = async () => {
    loader.start();
    alert.clear();
    try {
      if (!reason) {
        throw new Error("REASON_REQUIRED");
      }
      await studio.management.deleteUser(id, { reason });
      return navigate("/?success=User deleted successfully", { replace: true });
    } catch (err: any) {
      loader.stop();
      alert.setErrorMessage(err.message);
    }
  };

  const isDisabled = (): boolean => {
    return !statusValue || !reason;
  };

  const hasLocalStrategy = (): boolean => {
    if (!identities || !identities.length) {
      return false;
    }
    return !!(identities.find(identity => identity.strategy === "local"));
  };

  const renderDeleteConfirmation = () => {
    return (
      <Overlay>
        <h1>Delete an auth account</h1>
        <p>
          You are about to delete the ScreenCloud auth account for <strong>{email}</strong>. This deletion
          will not affect their studio organizations or data in anyway.
        </p>
        <p>
          Once deleted, the user will have to sign up again. As long as the same email address is used during sign up, the user
          will immediately gain access to their Studio organizations.
        </p>
        <p>
          <Textarea
            required
            value={reason}
            placeholder="Why are you deleting this account? (E.g. I deleted this auth account for GDPR reasons)"
            onChange={setReason}
          />
        </p>
        <p>
          <Button color="red" onClick={onDelete}>Yes, delete the account</Button>
          <Button onClick={() => setConfirmDelete(false)}>No, I've changed my mind</Button>
        </p>
      </Overlay>
    );
  };

  if (!email && loader.isLoading) {
    return loader.render();
  }

  return (
    <>
      {loader.render()}
      {alert.render()}
      {confirmDelete && renderDeleteConfirmation()}
      <Section>
        <Form onSubmit={onSubmit}>
          <Box slim color="white">
            <GroupTitle>Manage user</GroupTitle>
            <Row>
              <Label>First name:</Label>
              <Field>
                <Input value={givenName} placeholder="Joe" onChange={setGivenName} />
              </Field>
            </Row>
            <Row>
              <Label>Last name:</Label>
              <Field>
                <Input value={familyName} placeholder="Bloggs" onChange={setFamilyName} />
              </Field>
            </Row>
            <Row>
              <Label>Email:</Label>
              <Field>
                <Readonly>{email}</Readonly>
              </Field>
            </Row>
            <Row>
              <Label>Status:</Label>
              <Field>
                <Dropdown
                  required
                  options={utils.selectOptions(UserStatus)}
                  value={utils.selectOption(statusValue, statusLabel)}
                  placeholder="Please Select..."
                  onChange={onStatusChange}
                />
              </Field>
            </Row>
            {hasLocalStrategy() && (
              <Row>
                <Label tip="Leave blank for no changes.">Change password:</Label>
                <Field>
                  <Input type="password" value={password} placeholder="New password" onChange={setPassword} />
                </Field>
              </Row>
            )}
            <Row>
              <Label tip="Your name and this reason will be logged against this change in the audit.">Reason for change:</Label>
              <Field>
                <Textarea
                  required
                  value={reason}
                  placeholder={hasLocalStrategy() ? "E.g. I changed the user's password at their request, as they were unable to do so themself." : "E.g. The user requested a name change."}
                  onChange={setReason}
                />
              </Field>
            </Row>
            <Row>
              <Buttons>
                <Button type="submit" disabled={isDisabled()} loading={loader.isLoading}>Save Changes</Button>
              </Buttons>
            </Row>
          </Box>
        </Form>
      </Section>

      <Section>
        <Box slim color="blue">
          <GroupTitle>User details</GroupTitle>
          <Row slim>
            <Label>Identities:</Label>
            <Field>
              <Readonly>
                {identities?.map(identity => (
                  <Provider
                    key={`${identity.provider}-${identity.connection}`}
                    provider={identity.provider || identity.strategy}
                  />
                ))}
              </Readonly>
            </Field>
          </Row>
          <Row slim>
            <Label>Picture:</Label>
            <Field>
              <Readonly><Avatar url={user.picture} email={email} size="large" /></Readonly>
            </Field>
          </Row>
          <Row slim>
            <Label>Logins:</Label>
            <Field>
              <Readonly>{user.login_count}</Readonly>
            </Field>
          </Row>
          <Row slim>
            <Label>Last logged in:</Label>
            <Field>
              <Readonly>{user.last_login_at ? dateFormat(user.last_login_at, "dd mmmm yyyy HH:MM") : "Never"}</Readonly>
            </Field>
          </Row>
          <Row slim>
            <Label>Created on:</Label>
            <Field>
              <Readonly>{dateFormat(user.created_at, "dd mmmm yyyy HH:MM")}</Readonly>
            </Field>
          </Row>
        </Box>
      </Section>

      <Section>
        <Box slim color="red">
          <GroupTitle>DANGER ZONE</GroupTitle>
          <Row>
            <Label tip="Will not affect their studio account at all.">Delete account</Label>
            <Field>
              <Button type="button" color="red" onClick={() => setConfirmDelete(true)}>Delete Account</Button>
            </Field>
          </Row>
        </Box>
      </Section>

    </>
  );
 
}