import { Icon } from "components/Icon";

import style from "./style.module.css";

interface Props {
  first: number;
  last: number;
  total: number;
  limit: number;
  onPrev: (offset: number) => void;
  onNext: (offset: number) => void;
}

export function Pagination({ first, last, total, limit, onPrev, onNext }: Props) {

  return (
    <div className={style.container}>
      {first !== 1 && (
        <span onClick={() => onPrev((first - limit) - 1)} className={style.prev} title="Previous page">
          <Icon icon="arrow_back_ios" />
        </span>
      )}
      <div className={style.pages}>
        Showing results {first} to {last} of {total}.
      </div>
      {last < total && (
        <span className={style.next} onClick={() => onNext(last)} title="Next page">
          <Icon icon="arrow_forward_ios" />
        </span>
      )}
    </div>
  );
 
}
