import { IAuthClaims } from "@screencloud/auth-sdk";
import { createContext } from "react";

export interface Context {
  claims: IAuthClaims | null | undefined;
  setClaims: React.Dispatch<React.SetStateAction<IAuthClaims | null | undefined>>;
  token: string | null | undefined;
  setToken: React.Dispatch<React.SetStateAction<string | null | undefined>>;
}

export const AppContext = createContext<Context>({
  claims: undefined,
  setClaims: () => {},
  token: undefined,
  setToken: () => {},
});