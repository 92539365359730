interface Props {
  children: React.ReactNode;
  onSubmit: () => void;
}

export function Form({ children, onSubmit }: Props) {
  
  return (
    <form onSubmit={(e) => { e.preventDefault(); onSubmit(); }}>
      {children}
    </form>
  );
 
}
