import classNames from "classnames";

import style from "./style.module.css";

interface Props {
  children: React.ReactNode;
  slim?: boolean;
}

export function Row({ children, slim = false }: Props) {
  
  return (
    <label className={classNames(style.container, slim && style.slim)}>
      {children}
    </label>
  );
 
}
