import { useAlert } from "components/Alert/useAlert";
import { Box } from "components/Box";
import { Grid } from "components/Layout/Grid";
import { Column } from "components/Layout/Grid/Column";
import { Section } from "components/Layout/Section";
import { useLoader } from "components/Loader/useLoader";
import { useEffect, useState } from "react";
import { studio } from "services";
import * as Types from "types";

import style from "./style.module.css";

export function Dashboard() {
  const loader = useLoader(true);
  const alert = useAlert();
  const [counts, setCounts] = useState<Types.StatsCounts.Response | undefined>(undefined);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const error = params.get("error");
    const success = params.get("success");
    if (error) {
      alert.setErrorMessage(error);
    }
    if (success) {
      alert.setSuccessMessage(success);
    }
    studio.management.statsCounts().then(stats => {
      setCounts(stats);
      loader.stop();
    }).catch(err => {
      loader.stop();
      alert.setErrorMessage(err.message);
    });
  }, []);

  if (loader.isLoading) {
    return loader.render();
  }

  return (
    <>
      {alert.render()}
      <Section>
        <Grid>
          <Column>
            <Box color="orange">
              <div className={style.stat}>
                <h1>{counts?.all}</h1>
                <p>Total users</p>
                <small>Not incl multiple identities</small>
              </div>
            </Box>
          </Column>
          <Column>
            <Box color="yellow">
              <div className={style.stat}>
                <h1>{counts?.local}</h1>
                <p>Local users</p>
                <small>Email &amp; password</small>
              </div>
            </Box>
          </Column>
          <Column>
            <Box color="green">
              <div className={style.stat}>
                <h1>{counts?.google}</h1>
                <p>Google users</p>
                <small>Signed up via Google</small>
              </div>
            </Box>
          </Column>
          <Column>
            <Box color="red">
              <div className={style.stat}>
                <h1>{counts?.linkedin}</h1>
                <p>LinkedIn users</p>
                <small>Signed up via LinkedIn</small>
              </div>
            </Box>
          </Column>
          <Column>
            <Box color="purple">
              <div className={style.stat}>
                <h1>{counts?.sso}</h1>
                <p>SSO users</p>
                <small>Enterprise SSO users</small>
              </div>
            </Box>
          </Column>
        </Grid>
      </Section>
    </>
  );
 
}