import axios, { Method } from "axios";
import { config } from "system/config";
import * as Types from "types";

class Management {
  
  /**
   * Search for users
   */
  public async searchUsers(payload: Types.UserSearch.Request) {
    return this.request<Types.UserSearch.Response>({
      url: "/users/search",
      data: payload,
    });
  }

  /**
   * Get a user
   */
  public async getUser(id: string) {
    return this.request<Types.UserGet.Response>({
      url: `/users/${id}`,
      method: "GET",
    });
  }

  /**
   * Edit a user
   */
  public async editUser(id: string, payload: Types.UserEdit.Request) {
    return this.request<Types.UserEdit.Response>({
      url: `/users/${id}`,
      data: payload,
      method: "PUT",
    });
  }

  /**
   * Delete a user
   */
  public async deleteUser(id: string, payload: Types.UserDelete.Request) {
    return this.request<Types.UserDelete.Response>({
      url: `/users/${id}`,
      data: payload,
      method: "DELETE",
    });
  }

  /**
   * Stats: counts
   */
  public async statsCounts() {
    return this.request<Types.StatsCounts.Response>({
      url: "/stats/counts",
      method: "GET",
    });
  }

  //
  // Private
  //

  /**
   * Make a request to the management API
   */
  private async request<T = Record<string, any>>(opts: { url: string; data?: unknown; method?: Method }): Promise<T> {
    try {
      const query = await axios.request<T>({
        method: opts.method || "POST",
        url: opts.url,
        data: opts.data,
        withCredentials: true,
        baseURL: `${config.id.service.url}/management`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      return query.data;
    } catch (err: any) {
      throw new Error(err.response.data.error.message);
    }
  }

}

export const management = new Management();