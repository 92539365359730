import * as Sdk from "@screencloud/auth-sdk";
import { config } from "system/config";

export interface BooleanResponse {
  success: boolean;
}
export interface CreateUserWithPasswordPayload {
  email: string;
  password: string;
  givenName: string;
  familyName: string;
  registrationCode: string;
}

class Id {
  public redirectUrl = `${window.location.protocol}//${window.location.host}`;
  public sdk: Sdk.AuthManager;
  
  public constructor() {
    this.sdk = new Sdk.AuthManager({
      service: { url: config.id.service.url },
      frontend: { url: config.id.frontend.url },
      debug: config.id.debug,
      autoSync: config.id.sync,
      autoRefresh: config.id.refresh,
    });
  }

  //
  // Session
  //

  /**
   * Refresh and get the users session (or null if none)
   * 
   * @description - preferred over get() and this will force a refresh
   */
  public async refresh() {
    try {
      return await this.sdk.refresh() || null;
    } catch (err) {
      return null;
    }
  }

  //
  // Util
  //

  /**
   * Check whether an email address is registered
   * 
   * @description - Regardless of strategy
   */
  public async userExistsByEmail(email: string) {
    const request = await this.sdk.userExistsByEmail(email);
    const exception = request as Sdk.AuthServiceErrorResponse;
    if (exception.error) {
      return null;
    }
    return request as Sdk.UserExistsByEmailResponse;
  }

}

export const id = new Id();