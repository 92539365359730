import classNames from "classnames";

import style from "./style.module.css";

interface Props {
  value: string;
  onChange: (value: string) => void;
  placeholder: string;
  required?: boolean;
  disabled?: boolean;
  className?: string;
}

export function Textarea({ value, placeholder, onChange, required = false, disabled = false, className }: Props) {
  
  return (
    <textarea
      value={value}
      placeholder={placeholder}
      required={required}
      disabled={disabled}
      onChange={(e) => onChange(e.target.value) }
      className={classNames("form__textarea", style.container, className)}
    />
  );
 
}
