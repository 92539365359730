import classNames from "classnames";

import style from "./style.module.css";

interface Props {
  value: string;
  onChange: (value: string) => void;
  placeholder: string;
  type?: "text" | "email" | "password";
  required?: boolean;
  disabled?: boolean;
  autoComplete?: boolean;
  className?: string;
  onKeyDown?: (key: string) => void;
}

export function Input({ value, placeholder, onChange, type = "text", required = false, disabled = false, autoComplete = false, className, onKeyDown }: Props) {
  
  return (
    <input
      type={type}
      value={value}
      placeholder={placeholder}
      required={required}
      disabled={disabled}
      autoComplete={!autoComplete ? "new-password" : undefined}
      onChange={(e) => onChange(e.target.value) }
      onKeyDown={(e) => onKeyDown && onKeyDown(e.key) }
      className={classNames("form__input", style.container, className)}
    />
  );
 
}
