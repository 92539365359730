import axios from "axios";
import { config } from "system/config";

export interface Signage {
  hasAny: boolean;
}

class Api {

  public constructor() {
    axios.defaults.baseURL = config.signage.backend;
  }

  /**
   * Check whether an email address is registered
   */
  public async userExistsByEmail(email: string): Promise<Signage> {
    const result: Signage = {
      hasAny: false,
    };
    try {
      const request = await axios.get<{ exists: boolean }>(
        "/users/exist",
        { params: { email } },
      );
      if (request.data && request.data.exists) {
        result.hasAny = true;
      }
      return result;
    } catch (err) {
      return result;
    }
    
  }

}

export const api = new Api();
