import classNames from "classnames";
import Select, { GroupBase } from "react-select";

import style from "./style.module.css";

type SelectOption = {
  value: string;
  label: string;
};
type SelectOptions = readonly (SelectOption | GroupBase<SelectOption>)[];

interface Props {
  options: SelectOptions;
  placeholder: string;
  value: SelectOption;
  onChange: (value: string, label: string) => void;
  required?: boolean;
  tip?: string;
  className?: string;
  "data-testid"?: string;
}

export function Dropdown({ options, placeholder, value, onChange, required = false, tip, className, "data-testid": dataTestid }: Props) {

  return (
    <label className={classNames(style.container, required && style.required, className)}>
      <Select
        className={classNames("form__dropdown", style.select)}
        onChange={(value) => onChange(value?.value || "", value?.label || "")}
        options={options}
        isSearchable={true}
        placeholder={placeholder}
        value={value.value ? value : undefined}
        data-testid={dataTestid}
        theme={theme => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: "#DDDDDD",
            primary50: "#DDDDDD",
            primary75: "#1F2027",
            primary: "#1F2027",
            neutral30: "#1F2027",
          },
        })}
      />
      {tip && <p className="tip shadow-white">{tip}</p>}
    </label>
  );

}
