/**
 * User status levels
 */
export const UserStatus = {
  active: "Active",
  pending: "Pending",
  disabled: "Disabled",
};

/**
 * Human readable errors from ID service
 */
export const IdErrorMessages: Record<string, string> = {
  ACCEPT_INVITE_ERROR: "There was a problem accepting the invite.",
  ACTIVATION_EMAIL_SENT: "Activation email has been resent successfully.",
  AUTH0_NO_RESPONSE: "There was a problem logging the user in. Please try again.",
  EMAIL_EXISTS: "This email is already registered with ScreenCloud.",
  INCORRECT_LOGIN: "This email address and/or password are incorrect.",
  INVALID_CODE: "The code you entered was not recognized. Please try again.",
  INVALID_CURRENT_PASSWORD: "Sorry, your current password was incorrect. Please try again.",
  INVALID_EMAIL: "Please enter a valid email address.",
  INVALID_PASSWORD: "Password must be at least 8 characters long and contain at least one uppercase and lowercase character and a number.",
  LOGIN_FAILED: "Sorry, there was a problem logging the user in. Please try again.",
  SIGNUP_FAILED: "Sorry, there was a problem creating the account. Please try again.",
  MISSING_EMAIL: "Please enter your email address.",
  MISSING_RESET_CODE: "This reset link is invalid. Please follow the exact link from your email.",
  VERIFICATION_CODE_SEND_FAILED: "There was a problem sending the verification email. Please try again later.",
  NOT_FOUND: "This resource could not be found.",
  ORG_CREATE_FAIL: "There was a problem creating the org. Please try again.",
  ORG_NOT_FOUND: "Organization not found.",
  PASSWORD_REQUIRED: "Please enter the password.",
  PASSWORD_RESET_SUCCESSFULLY: "The password has been successfully reset. The user can now login using the new password.",
  RESET_EMAIL_ERROR: "There was a problem sending the reset email. Please try again.",
  SSO_NO_REGIONS: "There was a problem getting regions for the organization",
  TERMS_CONDITION: "Please review the Terms and Conditions to continue.",
  USER_IDENTITY_NOT_LINKED: "This email address is already registered. Please login with that account.",
  USER_LOCKED: "This account has been temporarily locked. Please try again later.",
  USER_STATUS_PENDING: "This user is still pending.",
  USERNAME_REQUIRED: "Please enter the email address.",
  REQUIRED_FIELDS: "Please fill in all required fields.",
  INVITE_EMAIL_INVALID: "The email address related to this invite is invalid. Please invite a valid email address.",
  ACCEPT_INVITE_FAILED: "There was a problem accepting the invite. Please try agaion later.",
  RATE_LIMITED: "Please wait a minute before requesting another code.",
  MAX_ATTEMPTS_EXCEEDED: "Please wait a few minutes before requesting another code.",
  NO_CODE_RETURNED: "There was a problem generating a verification code. Please try again later.",
  INVALID_REGISTRATION_CODE: "The code you entered was incorrect. Please try again.",
  REASON_REQUIRED: "Please enter the reason for this action.",
  UNAUTHORIZED: "You are not authorized to perform this action.",
  FORBIDDEN: "You do not have sufficient permissions to perform this action.",
  BAD_REQUEST: "Request body was empty.",
  UNPROCESSABLE_ENTITY: "Looks like you missed something.",
};