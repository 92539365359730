import { useState } from "react";

import style from "./style.module.css";

export function useLoader(defaultState = false) {
  const [isLoading, setIsLoading] = useState(defaultState);

  const start = () => {
    setIsLoading(true);
  };

  const stop = () => {
    setIsLoading(false);
  };

  const render = () => {
    if (!isLoading) {
      return null;
    }
    return (
      <div className={style.container}>
        <div className={style.indeterminate} />
      </div>
    );
  };

  return { isLoading, start, stop, render };
}
