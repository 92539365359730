import { Button } from "components/Button";
import { config } from "system/config";

import style from "./style.module.css";

interface Props {
  children: React.ReactNode;
  showLogin?: boolean;
}

export function AppStatus({ children, showLogin = false }: Props) {
  
  const onLoginClick = () => {
    window.location.replace(`${config.id.frontend.url}/logout?redirect=${window.location.origin}`);
  };

  return (
    <div className={style.container}>
      <div className={style.inner}>
        <h1>{children}</h1>
        {showLogin && <Button onClick={onLoginClick}>Login</Button>}
      </div>
    </div>
  );
 
}
