import { Header } from "components/Layout/Header";
import { Main } from "components/Layout/Main";
import { Outlet } from "react-router-dom";

export function Layout() {

  return (
    <>
      <Header />
      <Main>
        <Outlet />
      </Main>
    </>
  );
 
}
