import classNames from "classnames";
import { Icon } from "components/Icon";
import { useState } from "react";
import { IdErrorMessages } from "system/constants";

import style from "./style.module.css";

export function useAlert() {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const clear = () => {
    setError("");
    setSuccess("");
  };

  const setErrorMessage = (message: string) => {
    clear();
    setError(message);
  };

  const setSuccessMessage = (message: string) => {
    clear();
    setSuccess(message);
  };

  const hasMessage = (): boolean => {
    return !!(error) || !!(success);
  };

  const render = () => {
    if (!error && !success) {
      return null;
    }
    return (
      <div className={classNames(style.container, style[error ? "error" : "success"])}>
        <span className={style.close} onClick={clear}><Icon icon="close" /></span>
        {IdErrorMessages[error] || error || success}
      </div>
    );
  };

  return { setErrorMessage, setSuccessMessage, hasMessage, clear, render };
}
