export const config = {
  environment: REACT_APP_ENV,
  id: {
    service: {
      url: REACT_APP_ID_SERVICE_URL,
    },
    frontend: {
      url: REACT_APP_ID_FRONTEND_URL,
    },
    refresh: REACT_APP_ID_REFRESH_SESSION === "true",
    sync: REACT_APP_ID_SYNC_SESSION === "true",
    debug: REACT_APP_ID_DEBUG === "true",
    skipVerify: REACT_APP_ID_SKIP_VERIFY === "true",
  },
  signage: {
    backend: REACT_APP_SIGNAGE_SERVICE_URL,
    frontend: REACT_APP_SIGNAGE_FRONTEND_URL,
  },
};
